import React from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'

import { Card, CardActionArea, CardContent, CardMedia, Typography, Link as MuiLink } from '@material-ui/core'
import clsx from 'clsx'

import { QAHeader, QAPanel, values } from '@karakuri-faq/component'

import { IconWidgetProps } from './interface'
// /category/${icon.reference}

type LinkProps = GatsbyLinkProps<unknown>
const Link: React.FC<LinkProps> = ({ children, to, activeClassName, partiallyActive }) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <MuiLink
        component={GatsbyLink}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        color="textSecondary"
        underline="none"
      >
        {children}
      </MuiLink>
    )
    // return (
    //   <GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
    //     {children}
    //   </GatsbyLink>
    // )
  }
  return (
    <MuiLink href={to} target="_blank" rel="noreferrer" color="textSecondary" underline="none">
      {children}
    </MuiLink>
  )
}
function referenceTo(reference?: string) {
  if (!reference) {
    return ''
  }
  if (reference.indexOf('http') === 0) {
    return reference
  }
  if (reference.indexOf('-') === -1) {
    return `/answer/${reference}`
  }
  return `/category/${reference}`
}
const IconLink: React.FC<{ reference?: string }> = ({ reference, children }) => {
  return <Link to={referenceTo(reference)}>{children}</Link>
}
export const IconWidget: React.FC<IconWidgetProps> = ({ layout }) => {
  return (
    <QAPanel>
      <QAHeader title={`${layout.title}`} />
      <div className="faq-panel-card-list">
        {values(layout.icons).map(icon => (
          <Card key={`${icon.id}`} className={clsx('faq-panel-card', { 'faq-panel-noimage': !icon.image })}>
            <CardActionArea component="div">
              <IconLink reference={icon.reference}>
                <CardContent className="faq-panel-card-content">
                  <Typography variant="h5">{icon.title}</Typography>
                </CardContent>
                {icon.image && <CardMedia className="faq-panel-card-media" component="img" image={icon.image} />}
              </IconLink>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </QAPanel>
  )
}
