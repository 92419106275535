import React from 'react'
import { navigate } from 'gatsby'

import { QAHeader, QAPanel, QAContent } from '@karakuri-faq/component'

import { ImportantWidgetProps } from './interface'

export const ImportantWidget: React.FC<ImportantWidgetProps> = ({ layout, data, answers }) => {
  const importants = data.allImportantYaml.edges.map(edge => edge.node)
  if (!importants || importants.length === 0) {
    return null
  }
  return (
    <QAPanel>
      <QAHeader title={`${layout.title || '重要なご質問'}`} />
      {importants.map(({ id }) => {
        const handleAnswerClick = React.useCallback(
          (e: React.MouseEvent<any, MouseEvent>) => {
            e.preventDefault()
            navigate(`answer/${id}?important=true`)
          },
          [id]
        )
        return <QAContent key={id} question={answers[id]} onClick={handleAnswerClick} />
      })}
    </QAPanel>
  )
}
