import React from 'react'

import {
  LayoutProps,
  WidgetsProps,
  InformationLayoutProps,
  ImportantLayoutProps,
  SearchLayoutProps,
  FrequentLayoutProps,
  CategoryLayoutProps,
  IconLayoutProps
} from './interface'
import { InformationWidget } from './InformationWidget'
import { ImportantWidget } from './ImportantWidget'
import { SearchWidget } from './SearchWidget'
import { FaqWidget } from './FaqWidget'

import { CategoryWidget } from './CategoryWidget'
import { IconWidget } from './IconWidget'

export function renderWidgets(layouts: LayoutProps[], { data, answers, pathContext }: WidgetsProps) {
  // console.log('widget', type, data)
  return layouts.map(layout => {
    switch (layout.type) {
      case 'information':
        return <InformationWidget key={layout.id} layout={layout as InformationLayoutProps} data={data} answers={answers} />
      case 'important':
        return <ImportantWidget key={layout.id} layout={layout as ImportantLayoutProps} data={data} answers={answers} />
      case 'search':
        return <SearchWidget key={layout.id} layout={layout as SearchLayoutProps} data={data} />
      case 'frequent':
        return <FaqWidget key={layout.id} layout={layout as FrequentLayoutProps} data={data} answers={answers} />
      case 'category':
        return <CategoryWidget key={layout.id} layout={layout as CategoryLayoutProps} data={data} pathContext={pathContext} />
      case 'icon':
        return <IconWidget key={layout.id} layout={layout as IconLayoutProps} />
      default:
        break
    }

    return null
  })
}
