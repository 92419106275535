export function search(inputValue: string, queryFAQ: any[]) {
  push({
    event: 'karakuri.faq.search',
    searchKeyword: inputValue,
    searchResultCount: queryFAQ ? queryFAQ.length : 0
  })
}

export function feedback(cardId: string, feedback: string) {
  push({
    cardId,
    event: 'karakuri.faq.feedback',
    feedback
  })
}

function push(data: any) {
  const dataLayer = window.dataLayer || []
  dataLayer.push(data)
}
