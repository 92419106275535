import React from 'react'
import { navigate } from 'gatsby'
import { useApolloClient } from 'react-apollo'

import { Search } from '@karakuri-faq/component'

import { search } from '../utils/gtm'
import { SearchWidgetProps } from './interface'
import { SEARCH_FAQ } from './graphql'

export const SearchWidget: React.FC<SearchWidgetProps> = ({ layout, data }) => {
  const client = useApolloClient()
  const botId = data.settingYaml.botId
  return (
    <div className="faq-panel">
      <h3 className="faq-panel-title">{layout.title || 'キーワード検索'}</h3>
      <Search
        onClick={navigate}
        onSearched={search}
        search={inputValue => client.query({ query: SEARCH_FAQ, variables: { botId, inputValue } })}
      />
    </div>
  )
}
