import React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { Page, values } from '@karakuri-faq/component'

import IndexLayout from '../layouts'

import { SEOWidget as SEO } from '../widgets/SEOWidget'
import { Data } from '../widgets/interface'
import { renderWidgets } from '../widgets/Widgets'
import { LayoutType } from '../graphql.d'

interface IndexPageProps {
  data: Data
  location: any
  pathContext: any
}

const IndexPage = ({ data, pathContext, location }: IndexPageProps) => {
  const answers = data.allAnswerYaml.edges.reduce<Record<string, string>>((acc, edge) => {
    const node = edge.node
    if (node.id) {
      acc[node.id] = node.ask || ''
    }
    return acc
  }, {})
  // const customContents = values(data.settingYaml.customContents).reduce<CustomContents>((acc, c) => {
  //   if (!acc[`${c.id}`]) {
  //     acc[`${c.id}`] = c
  //   }
  //   return acc
  // }, {})
  const widgetProps = {
    data,
    answers,
    // customContents,
    pathContext
  }
  const left = values(data.settingYaml.layoutLeft, [])
  const right = values(data.settingYaml.layoutRight, [])
  const main = values(
    data.settingYaml.layoutMain,
    (left.length === 0 &&
      right.length === 0 && [
        { id: 'search', type: LayoutType.Search },
        { id: 'important', type: LayoutType.Important },
        { id: 'frequent', type: LayoutType.Frequent },
        { id: 'category', type: LayoutType.Category }
      ]) ||
      []
  )
  // console.log(layout)
  return (
    <IndexLayout>
      <SEO pathname={location.pathname} title="よくあるご質問" />
      <Page className="faq-content">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>よくあるご質問 TOP</h2>
          </div>
          {renderWidgets(main, widgetProps)}
        </div>
        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>{renderWidgets(left, widgetProps)}</div>
        <div className={clsx('faq-right-content', { 'faq-no-content': right.length === 0 })}>{renderWidgets(right, widgetProps)}</div>
      </Page>
    </IndexLayout>
  )
}
export default IndexPage

export const query = graphql`
  query IndexData {
    settingYaml(ignore: { ne: true }) {
      botId
      layoutMain {
        id
        title
        type
        icons {
          id
          title
          reference
          image
        }
      }
      layoutLeft {
        id
        title
        type
        icons {
          id
          title
          reference
          image
        }
      }
      layoutRight {
        id
        title
        type
        icons {
          id
          title
          reference
          image
        }
      }
      customContents {
        id
        type
        title
        parts {
          id
          title
          image
        }
      }
    }
    allAnswerYaml(filter: { ignore: { ne: true } }) {
      edges {
        node {
          id
          ask
          contents {
            text
            contentFormat
            image
          }
          actions {
            actions {
              id
              label
            }
          }
        }
      }
    }
    allImportantYaml(filter: { ignore: { ne: true } }) {
      edges {
        node {
          id
          title
        }
      }
    }
    allCategoryYaml(filter: { ignore: { ne: true } }) {
      edges {
        node {
          id
          title
          sub {
            id
            title
          }
        }
      }
    }
    allInformationYaml(filter: { ignore: { ne: true } }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
