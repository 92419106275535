import gql from 'graphql-tag'

export const SEARCH_FAQ = gql`
  query allFAQ($botId: String!, $inputValue: String!) {
    queryFAQ(query: { botId: $botId, text: $inputValue, count: 20 }) {
      id
      title
    }
  }
`
