import React from 'react'
import { navigate } from 'gatsby'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

import { QAHeader, QAPanel, QAContent } from '@karakuri-faq/component'

import { FaqWidgetProps } from './interface'

const TOP_FAQ = gql`
  query topPageViewFAQ($botId: ID!) {
    topPageViewFAQs(botId: $botId) {
      id
      title
    }
  }
`

interface Data {
  topPageViewFAQs: {
    id: string
    title: string
  }[]
}

export const FaqWidget: React.FC<FaqWidgetProps> = ({ layout, answers, data }) => {
  const botId = data.settingYaml.botId
  const { loading, data: topFaqData } = useQuery<Data>(TOP_FAQ, { variables: { botId } })
  if (loading || !topFaqData || !topFaqData.topPageViewFAQs || topFaqData.topPageViewFAQs.length === 0) {
    return null
  }
  return (
    <QAPanel>
      <QAHeader title={`${layout.title || 'よくあるご質問'}`} />
      {topFaqData.topPageViewFAQs.map(x => {
        return (
          <QAContent
            key={x.id}
            question={answers[x.id]}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              navigate(`answer/${x.id}?frequent=true`)
            }}
          />
        )
      })}
    </QAPanel>
  )
}
