import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Typography, makeStyles } from '@material-ui/core'
import cx from 'clsx'

import '../styles/index.scss'
import { Session } from '../session'
import { SettingYaml } from '../graphql'

type StaticQueryProps = {
  settingYaml: SettingYaml
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    // maxWidth: '840px',
    margin: '0 auto'
  },
  header: {
    display: 'flex',
    backgroundColor: 'white',
    // borderBottom: `1px solid ${theme.palette.secondary.main}`,
    padding: 0
  },
  spacer: {
    flex: 'auto'
  },
  title: {
    'align-self': 'flex-end',
    marginBottom: 2,
    fontWeight: 600
  },
  paper: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  container: {
    // 'max-width': 840,
    margin: '0 auto'
  }
}))

interface IndexLayoutProps {}
const IndexLayout: React.FC<IndexLayoutProps> = ({ children }) => {
  const classes = useStyles()
  React.useEffect(() => Session.init(), [])
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingYaml(ignore: { ne: true }) {
            botId
            title
            headerImageUrl
            headerImageAltText
            headerImageLinkUrl
            faviconUrl
            gtags
            styles
            scripts
            header
            footer
          }
        }
      `}
      render={(data: StaticQueryProps) => {
        const gtags = ((data.settingYaml && data.settingYaml.gtags && data.settingYaml.gtags) || []).filter((s): s is string => !!s)
        const styleLinks = (data.settingYaml.styles || []).filter((s): s is string => !!s)
        const headerImageLinkUrl = data.settingYaml.headerImageLinkUrl || ''
        const headerImageUrl = data.settingYaml.headerImageUrl || ''
        const headerImageAlt = data.settingYaml.headerImageAltText || data.settingYaml.title || ''
        const header = data.settingYaml.header || ''
        const footer = data.settingYaml.footer || ''
        const scripts = (data.settingYaml.scripts || []).filter((s): s is string => !!s)
        const faviconUrl = data.settingYaml.faviconUrl || ''

        return (
          <>
            {gtags.map(gtag => (
              <noscript key={gtag}>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${gtag}`}
                  height="0"
                  width="0"
                  style={{ display: 'none', visibility: 'hidden' }}
                />
              </noscript>
            ))}
            <div className={classes.root}>
              <Helmet
                title={data.settingYaml.title || ''}
                meta={
                  [
                    // { name: 'description', content: data.site.siteMetadata.description },
                    // { name: 'keywords', content: 'gatsbyjs, gatsby, javascript, sample, something' }
                  ]
                }
                bodyAttributes={{ class: cx('faq') } as any}
                htmlAttributes={{ class: `b-${data.settingYaml.botId}` } as any}
              >
                {styleLinks.map(style => (
                  <link rel="stylesheet" href={style} key={style} />
                ))}
                {gtags.map(gtag => (
                  <script
                    key={gtag}
                  >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtag}');`}</script>
                ))}
                {faviconUrl && ['icon', 'shortcut icon', 'apple-touch-icon'].map(rel => <link key={rel} rel={rel} href={faviconUrl} />)}
              </Helmet>
              {header ? (
                <header dangerouslySetInnerHTML={{ __html: header }} />
              ) : (
                <header>
                  <div className={classes.header}>
                    <h1 className="faq-logo">
                      {headerImageLinkUrl ? (
                        <a href={headerImageLinkUrl}>
                          <img src={headerImageUrl} alt={headerImageAlt} />
                        </a>
                      ) : (
                        <img src={headerImageUrl} alt={headerImageAlt} />
                      )}
                    </h1>
                    <div className={classes.spacer} />
                    <Typography paragraph className={classes.title} variant="caption">
                      よくあるご質問（Q&A）
                    </Typography>
                  </div>
                </header>
              )}
              <div className={cx(classes.container)}>{children}</div>
              <footer dangerouslySetInnerHTML={{ __html: footer }} />
            </div>
            {scripts.map(s => (
              <script src={s} key={s} />
            ))}
          </>
        )
      }}
    />
  )
}

export default IndexLayout
