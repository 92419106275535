import React from 'react'
import { navigate } from 'gatsby'

import { QAHeader, QAPanel, QAContent } from '@karakuri-faq/component'

import { InformationWidgetProps } from './interface'

export const InformationWidget: React.FC<InformationWidgetProps> = ({ layout, answers, data }) => {
  const informations = data.allInformationYaml.edges.map(edge => edge.node)
  if (!informations || informations.length === 0) {
    return null
  }
  return (
    <QAPanel>
      <QAHeader title={`${layout.title || 'お知らせ'}`} />
      {informations.map(({ id }) => {
        const handleAnswerClick = React.useCallback(
          (e: React.MouseEvent<any>) => {
            e.preventDefault()
            navigate(`answer/${id}`)
          },
          [id]
        )
        return <QAContent key={id} question={answers[id]} onClick={handleAnswerClick} info />
      })}
    </QAPanel>
  )
}
