import React from 'react'
import { navigate } from 'gatsby'

import { QAHeader, QAPanel, QACategory, values } from '@karakuri-faq/component'

import { CategoryWidgetProps } from './interface'

export const CategoryWidget: React.FC<CategoryWidgetProps> = ({ data, pathContext }) => {
  const categories = data.allCategoryYaml.edges.map(edge => edge.node)
  if (!categories || categories.length === 0) {
    return null
  }
  const hashId = typeof window !== 'undefined' && !!window.location && !!window.location.hash && window.location.hash.substring(1)
  const expandable = (id: string, sub: { id: string }[]) => hashId === id || sub.some(s => pathContext.id === s.id)
  return (
    <QAPanel className="faq-panel-category">
      <QAHeader title="カテゴリから探す" />
      {categories.map(({ id, title, sub }) => (
        <QACategory key={id || ''} id={id} title={title} sub={sub} expand={expandable(id, values(sub))} onCategoryClick={navigate} />
      ))}
    </QAPanel>
  )
}
