export class Session {
  static init() {
    try {
      if (typeof window !== 'undefined' && !!window.location && !!window.location.search) {
        const matched = window.location.search.match(/(?:\?|&)session=([^&]+?)(&|$)/)
        if (!!matched) {
          localStorage.session = JSON.stringify({ session: matched[1], createdAt: new Date().toISOString() })
        }
      }
      if (localStorage.session) {
        const session: { session: string; createdAt: string } = JSON.parse(localStorage.session)
        if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < new Date().getTime()) {
          localStorage.session = JSON.stringify({})
        }
      }
    } catch (e) {
      return
    }
  }

  static get value() {
    try {
      if (localStorage.session) {
        const session: { session: string; createdAt: string } = JSON.parse(localStorage.session)
        return session.session
      }
      return ''
    } catch {
      return ''
    }
  }
}
